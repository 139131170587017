<!-- 限时秒杀添加商品弹窗 -->
<template>
  <el-dialog :title="title"
             :visible.sync="visibleValue"
             :fullscreen="fullscreen"
             :modal="modal"
             :modal-append-to-body="modalAppendToBody"
             :append-to-body="appendToBody"
             :custom-class="customClass"
             :close-on-click-modal="closeOnClickModal"
             :close-on-press-escape="closeOnPressEscape"
             :show-close="showClose"
             :before-close="beforeClose"
             :destroy-on-close="destroyOnClose"
             :width="width"
             @closed="closed">
    <el-form :inline="true"
             class="demo-form-inline"
             @submit.native.prevent>
      <el-form-item>
        <el-input class=""
                  placeholder="搜索商品名"
                  prefix-icon="el-icon-search"
                  v-model="searchData.goodsName"
                  @keyup.enter.native="search"
                  style="width: 260px">
          <el-button slot="append"
                     icon="el-icon-search"
                     @click="search"></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="flex-item select-brand-list">
      <div class="left">已选中（{{selectArray.length}}）：</div>
      <div class="flex-1 text-over-hidden">
        <!--{{selectArrayText}}-->
        <span class="margin-right-10"
              v-for="item in selectArray">{{item[matchLabelKey]}}</span>
      </div>
    </div>

    <el-table class=""
              ref="table"
              :data="tableData"
              @select="selection"
              @select-all="selectionAll"
              :span-method="spanMethod"
              height="400px">
      <el-table-column prop="goodsCover"
                       label="logo"
                       width="280">
        <template slot-scope="scope">
          <div class="flex-item">
            <img :src="scope.row.goodsCover"
                 alt=""
                 class="table-row-image-60" />
            <div class="flex-1 margin-left-10">{{scope.row.goodsName}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column type="selection"
                       align="center"
                       width="50"
                       :selectable="canOrNotSelectable"></el-table-column>
      <el-table-column prop="goodsName"
                       label="价格"
                       width="140">
        <template slot-scope="scope">
          <div class="">售价：{{scope.row.originalPrice}}</div>
          <div class=""
               v-if="showPoint">供货价：{{scope.row.originalPurchasePrice}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="goodsSkuName"
                       label="规格"
                       width="100"></el-table-column>
      <el-table-column prop="stockNum"
                       label="库存"
                       width="80"></el-table-column>
      <el-table-column prop="selectStatus"
                       label="是否可选"
                       width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.selectStatus === 2">可选</template>
          <template v-else>{{scope.row.selectContent || '不可选'}}</template>
        </template>
      </el-table-column>
    </el-table>

    <div class="paging-row">
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="[5, 10, 15]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="totalListNum">
      </el-pagination>
    </div>

    <div class="action">
      <el-button class=""
                 type=""
                 @click="close">取消</el-button>
      <el-button class=""
                 type="primary"
                 @click="submit">确认</el-button>
    </div>

  </el-dialog>
</template>

<script>
import { templateElDialog } from '../../public/template/template-el-dialog'
import { URL } from '../../../config'
import { mapState } from 'vuex'

export default {
  name: 'selectSecondsSkillGoods',
  mixins: [templateElDialog],
  props: {
    title: {
      type: String,
      default: '选择商品',
    },
    width: {
      type: String,
      default: '800px',
    },
    defaultSelect: { // 已选中[{id:'',name:''},{}...]
      type: Array,
      // default:function(){
      //   return []
      // },
    },
    closeOnClickModal: { // 是否可以通过点击 modal 关闭 Dialog
      type: Boolean,
      default: false
    },
    startTime: String,
    endTime: String,
  },
  data () {
    return {
      loading: false,
      /* 数据表格 */
      searchData: {
        goodsName: '',
      },
      tableData: [], // 数据列表
      currentPage: 1, // 当前页码
      totalListNum: 0, // 列表总条量
      pageSize: 5, // 每页显示得条数
      /* 选中 */
      selectArray: [],
      /* 匹配关键字 */
      matchKey: 'goodsSkuId',
      matchLabelKey: 'goodsName',
    }
  },
  created () {
    if (Array.isArray(this.defaultSelect)) this.selectArray = this.defaultSelect.slice()
  },
  methods: {
    /**
     * 拼接搜索参数
     */
    jointSearchData () {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      }
      let startTime = this.startTime
      let endTime = this.endTime
      startTime = new Date(startTime || '').getTime()
      endTime = new Date(endTime || '').getTime()
      data = Object.assign(data, this.searchData, {
        startTime: startTime,
        endTime: endTime,
      })
      return data
    },
    /**
     * 搜索
     */
    search () {
      this.currentPage = 1
      this.getList()
    },
    /**
     * 查询列表数据
     */
    getList () {
      return new Promise((resolve, reject) => {
        const key = this.matchKey
        const data = this.jointSearchData()
        this.loading = true
        this.axios.get(URL.supplierSpikeGoods.spikeGoodsAddListGroupGoodsId, { params: data }).then(res => {
          const list = []
          res.data.records.forEach(goods => {
            goods.addNewBasisModelList.forEach(item => {
              item.goodsName = goods.goodsName
              item.goodsCover = goods.goodsCover
              item.originalPrice = item.price
              item.originalPurchasePrice = item.purchasePrice
              delete item.price
              delete item.purchasePrice
            })
            list.push(...goods.addNewBasisModelList)
          })
          this.totalListNum = parseInt(res.data.total)
          this.tableData = list
          this.$nextTick(() => {
            this.tableData.forEach(item => {
              if (this.selectArrayIds.includes(item[key])) this.$refs.table.toggleRowSelection(item, true)
            })
          })
          resolve(res)
        }).catch(() => {
          this.tableData = []
        }).finally(() => {
          this.loading = false
        })
      })
    },
    /**
     * pageSize 改变时会触发：选择每页显示条数
     */
    handleSizeChange (val, type) {
      this.pageSize = val
      this.currentPage = 1
      this.getList()
    },
    /**
     * currentPage 改变时会触发：选择页数
     */
    handleCurrentChange (val, type) {
      this.currentPage = val
      this.getList()
    },
    /**
     * 是否可以选中
     */
    canOrNotSelectable (row, index) {
      const key = this.matchKey
      if (row.selectStatus === 1) return false
      if (Array.isArray(this.defaultSelect)) {
        return !this.defaultSelectIds.includes(row[key])
      } else {
        return true
      }
    },
    selection (selection, row) {
      const key = this.matchKey
      if (this.selectArrayIds.includes(row[key])) {
        this.selectArray = this.selectArray.filter(res => res[key] !== row[key])
      } else {
        this.selectArray.push(row)
      }
    },
    selectionAll (selection) {
      const key = this.matchKey
      selection.forEach(row => {
        if (!this.selectArrayIds.includes(row[key])) {
          this.selectArray.push(row)
        }
      })
      const selectionIds = selection.map(res => res[key])
      const noSelection = this.tableData.filter(res => !selectionIds.includes(res[key]))
      noSelection.forEach(row => {
        if (this.selectArrayIds.includes(row[key])) {
          this.selectArray = this.selectArray.filter(res => res[key] !== row[key])
        }
      })
    },
    /**
     * 功能
     * */
    close () {
      this.visibleValue = false
    },
    submit () {
      this.$emit('change', this.selectArray)
      this.close()
    },
    /**
     * 表单合并
     */
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const goodsIds = this.skuListGoodsIds
        if (this.repetitiveGoodsList.includes(row.goodsId)) {
          const index = goodsIds.indexOf(row.goodsId)
          if (index === rowIndex) {
            const num = goodsIds.filter(id => id === row.goodsId).length
            return [num, 1]
          } else {
            return [0, 0]
          }
        }
      }
      return [1, 1]
    },
  },
  computed: {
    ...mapState({
      showPoint: state => state.userInfo.showCostAndPurchase,
    }),
    defaultSelectIds () {
      const key = this.matchKey
      return this.defaultSelect.map(res => res[key])
    },
    selectArrayIds () {
      const key = this.matchKey
      return this.selectArray.map(res => res[key])
    },
    selectArrayText () {
      return this.selectArray.map(res => res[this.matchLabelKey]).toString()
    },
    // 计算已选择商品sku列表的goodsIds
    skuListGoodsIds () {
      return this.tableData.map(res => res.goodsId)
    },
    // 计算已选择商品sku列表中存在复数goodsID的列表
    repetitiveGoodsList () {
      const goodsIds = this.skuListGoodsIds
      const ids = Array.from(new Set(goodsIds)).filter((id, index) => {
        return goodsIds.filter(res => res === id).length > 1
      })
      console.log(ids)
      return ids
    },
  },
  watch: {
    visibleValue (newValue) {
      this.handleSizeChange(10)
    },
    defaultSelect (newValue) {
      this.selectArray = this.defaultSelect.slice()
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../assets/css/data";
.select-brand-list {
  margin-top: -20px;
}
.brand-item {
  display: inline-block;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;
  margin: 0 10px 0 0;
}
.action {
  text-align: right;
  .el-button {
    width: 100px;
  }
}
</style>
