<template>
  <div class="width-all"
       id="supplierEditSecondsKillEdit"
       @scroll.navite="mainScrollEvent">
    <el-form class=""
             :model="data"
             :ref="formNameEdit"
             v-loading="loading"
             label-width="160px"
             @submit.native.prevent>

      <div class="edit-module">
        <div class="edit-header">
          <div class="left title">基础设置</div>
          <div class="content"></div>
        </div>
        <el-form-item label="活动名称"
                      class="form-item max-width"
                      prop="name"
                      :rules="formRules.required">
          <el-input v-model="data.name"
                    placeholder="请填该次秒杀活动名称"
                    :disabled="disabledEdit"></el-input>
        </el-form-item>
        <el-form-item class="form-item max-width"
                      label="活动时间"
                      prop="endTime"
                      :rules="formRules.required">
          <el-date-picker v-model="activityTimeArray"
                          style="width: 100%"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm"
                          :disabled="disabledTime"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          @change="changeActivityTime">
          </el-date-picker>
        </el-form-item>
        <el-form-item class="form-item max-width"
                      label="活动主题">
          <el-button style="margin-right: 10px; "
                     type="primary"
                     @click="openSelectTheme"
                     v-if="!disabledEdit">选择活动主题</el-button>
          <el-input style="width: 280px"
                    v-model="themeText"
                    :placeholder="!disabledEdit ? '请选择活动主题' : '未选择主题'"
                    :disabled="true"></el-input>
          <el-tooltip class="item"
                      effect="dark"
                      content="未选主题时，将使用活动默认主题"
                      placement="top"
                      style="margin-left: 10px">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item class="form-item max-width"
                      label="活动预告"
                      prop="isNotice"
                      :rules="formRules.required">
          <el-radio-group v-model="data.isNotice"
                          :disabled="disabledEdit">
            <el-radio :label="1">创建成功后进行活动预告</el-radio>
            <el-radio :label="2">不进行活动预告</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="form-item max-width"
                      label="限购规则"
                      prop="isPurchaseLimit"
                      :rules="formRules.required">
          <el-radio-group v-model="data.isPurchaseLimit"
                          :disabled="disabledEdit"
                          @change="changeType">
            <el-radio :label="0"
                      style="margin-bottom: 10px; margin-right:300px">
              <span>不限购</span>
            </el-radio>
            <el-radio :label="1">
              <span>每人每种商品限购</span>
              <el-input class="goods-num"
                        :disabled="data.isPurchaseLimit != 1"
                        v-model="purchaseLimitNum1"
                        style="width:200px"
                        type="number"
                        size="mini"
                        placeholder="请输入限购数量"></el-input><span>件，超出无法购买</span>
            </el-radio>
            <el-radio :label="2">
              <span>每人每种商品前</span>
              <el-input class="goods-num"
                        :disabled="data.isPurchaseLimit != 2"
                        v-model="purchaseLimitNum2"
                        style="width:200px"
                        type="number"
                        size="mini"
                        placeholder="请输入享受秒杀价的数量"></el-input><span>件内享受秒杀价，超出后默认不参与秒杀活动 全部按原价购买</span>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="edit-module">
        <div class="edit-header">
          <div class="left title">活动商品</div>
          <el-button class="left"
                     icon="el-icon-plus"
                     type="primary"
                     @click="showSelectGoods"
                     :disabled="disabledEdit">添加商品</el-button>
          <div class="left color-info">已选中{{this.goodsSkuList.length}}条（最多选择{{maxLength}}条）</div>
          <div class="content"></div>
          <div class="right"
               style="font-size: 16px;">商品筛选：</div>
          <el-input class="margin-left-10"
                    style="width: 230px;"
                    v-model="keywords"
                    placeholder="商品、规格名称（已选中商品）"></el-input>
        </div>
        <div class="select-goods-list">
          <el-table :data="goodsSkuListFilterAfter"
                    @select="selection"
                    @select-all="selectionAll"
                    :span-method="spanMethod"
                    max-height="500px"
                    empty-text="请至少选择一件活动商品">
            <el-table-column prop="goodsCover"
                             label="logo"
                             width="280">
              <template slot-scope="scope">
                <div class="flex-item">
                  <img :src="scope.row.goodsCover"
                       alt=""
                       class="table-row-image-60" />
                  <div class="flex-1 margin-left-10">{{scope.row.goodsName}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column type="selection"
                             align="center"
                             width="50"></el-table-column>
            <el-table-column prop="originalPrice"
                             class-name="input-td"
                             label="售价(元)"
                             width="140">
              <template slot-scope="scope">
                <div class="">原售价：{{scope.row.originalPrice}}</div>
                <div class=""
                     v-if="showPoint">原供货价：{{scope.row.originalPurchasePrice}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="goodsSkuName"
                             label="规格"
                             width="100"></el-table-column>
            <el-table-column prop="stockNum"
                             class-name="input-td"
                             label="库存"
                             width="100">
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.spikeStockNum'"
                              :rules="formRules.stockNum">
                  <el-input v-model="scope.row.spikeStockNum"
                            type="number"
                            :disabled="disabledEdit"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="price"
                             class-name="input-td"
                             label="秒杀价(元)"
                             width="100">
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.price'"
                              :rules="formRules.price">
                  <el-input v-model="scope.row.price"
                            type="number"
                            @change="editPrice(scope.row)"
                            :disabled="disabledEdit"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="purchasePrice"
                             label="供货价(元)"
                             class-name="input-td"
                             width="100"
                             v-if="showPoint">
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.purchasePrice'"
                              :rules="formRules.purchasePrice"
                              v-if="scope.row.supplierType === 1">
                  <el-input v-model="scope.row.purchasePrice"
                            type="number"
                            @change="editPurchasePrice(scope.row)"
                            :disabled="disabledEdit"></el-input>
                </el-form-item>
                <template v-else>
                  {{scope.row.purchasePrice}}
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="costPercent"
                             label="扣点%"
                             class-name="input-td"
                             width="120"
                             v-if="showPoint">
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.costPercent'"
                              :rules="formRules.costPercent"
                              v-if="scope.row.supplierType === 1">
                  <el-input v-model="scope.row.costPercent"
                            type="number"
                            @change="editCostPercent(scope.row)"
                            :disabled="disabledEdit"></el-input>
                </el-form-item>
                <template v-else>
                  {{scope.row.costPercent}}
                </template>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button type="danger"
                           size="mini"
                           @click="clickDeleteSelection(scope.row)"
                           :disabled="disabledEdit">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="flex-item margin-tb">
            <el-button class="left"
                       type="danger"
                       @click="clickDeleteSelectionAll"
                       :disabled="selectThePageSkuArray.length<1">删除选中</el-button>
            <div class="content"></div>
          </div>
        </div>
      </div>
      <div class=""
           style="height: 200px;"></div>
      <div class="edit-module"
           style="margin-bottom: 100px;"
           v-if="!1">
        <div class="padding-right-map map-left-width text-center">
          <el-button class="edit-module__button"
                     type="warning"
                     @click="pushTestData"
                     v-if="$store.state.isTest">
            <el-icon class="el-icon-warning margin-right-10" />测试数据
          </el-button>
          <el-button class="edit-module__button"
                     type="primary"
                     @click="submitForm()">
            <el-icon class="el-icon-edit-outline margin-right-10" />保存
          </el-button>
        </div>
      </div>
      <div class="fixed-action-right-bottom edit-module__action">
        <el-button type=""
                   @click="goBackTop"
                   v-if="showBackTop">
          <el-icon class="el-icon-top" />返回顶部
        </el-button>
        <el-button type=""
                   @click="goBack">
          <el-icon class="el-icon-back" />返回上页
        </el-button>
        <el-button class=""
                   type="warning"
                   @click="pushTestData"
                   v-if="$store.state.isTest">
          <el-icon class="el-icon-warning" />测试数据
        </el-button>
        <el-button type="primary"
                   @click="submitForm()">
          <el-icon class="el-icon-edit-outline" />保存
        </el-button>
      </div>

      <select-seconds-skill-goods :visible.sync="dialogVisibleSelectGoods"
                                  :default-select="selectGoodsIdArray"
                                  :start-time="activityTimeArray[0]"
                                  :end-time="activityTimeArray[1]"
                                  @change="selectGoodsChange"></select-seconds-skill-goods>
      <el-dialog title="选择活动主题"
                 :visible.sync="dialogVisible"
                 width="30%"
                 :destroy-on-close="true"
                 :before-close="handleClose">
        <selectThemeDialog :configId="data.configId"
                           ref="selectThemeDialog"></selectThemeDialog>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="confirmTheme">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>
  </div>

</template>

<script>
import { required } from '../../../assets/js/formRules'
import { isPostageFree, goodsManufactureTypeArray, supportRefundArray } from '../../../assets/js/status'
import { parseTime, deepsClone, JSONStringify, goBack, goHome, isNumber } from '../../../assets/js/utils'
import { URL } from '../../../config/url.supplier'
import { returnBaseDataSecondsKill } from '../../../assets/js/modules/secondsKill/secondsKill'
import selectSecondsSkillGoods from '../../../components/supplier/secondsSkill/selectSecondsSkillGoods'
import selectThemeDialog from './selectThemeDialog'

import { mapState } from 'vuex'

export default {
  name: 'secondsKillEdit',
  components: {
    selectSecondsSkillGoods,
    selectThemeDialog
  },
  created () {
    this.pageInit()
  },
  data () {
    const checkPrice = (rule, value, callback) => {
      const index = rule.field.match(/[0-9]+/g)
      const row = this.goodsSkuList[index]
      const errorText = this.judgeSecondsKillEditPrice(row)
      if (errorText) return callback(new Error(errorText))
      callback()
    }
    const checkStockNum = (rule, value, callback) => {
      const index = rule.field.match(/[0-9]+/g)
      const row = this.goodsSkuList[index]
      const errorText = this.judgeSecondsKillEditStockNum(row)
      if (errorText) return callback(new Error(errorText))
      callback()
    }
    const checkPurchasePrice = (rule, value, callback) => {
      const index = rule.field.match(/[0-9]+/g)
      const row = this.goodsSkuList[index]
      const errorText = this.judgeSecondsKillEditPurchasePrice(row)
      if (errorText) return callback(new Error(errorText))
      callback()
    }
    const checkCostPercent = (rule, value, callback) => {
      const index = rule.field.match(/[0-9]+/g)
      const row = this.goodsSkuList[index]
      const errorText = this.judgeSecondsKillEditCostPercent(row)
      if (errorText) return callback(new Error(errorText))
      callback()
    }
    return {
      loading: false,
      // 容器滚动条
      showBackTop: false,
      scrollTop: 0,
      /* 路由 */
      originPageUrL: '',
      /* 编辑 */
      formRules: Object.assign({
        price: [{ trigger: 'blur', validator: checkPrice, },],
        stockNum: [{ trigger: 'blur', validator: checkStockNum, }],
        purchasePrice: [{ trigger: 'blur', validator: checkPurchasePrice, }],
        costPercent: [{ trigger: 'blur', validator: checkCostPercent, }],
        topicName: ''
      }, { required }),
      formNameEdit: 'formNameEditSecondsKill',
      data: returnBaseDataSecondsKill(),
      activityTimeArray: [],
      /* 已选中商品表格 */
      maxLength: 100,
      keywords: '', // 筛选
      matchKey: 'goodsSkuId',
      selectThePageSkuArray: [],
      /* 选择商品弹窗 */
      dialogVisibleSelectGoods: false,
      selectGoodsIdArray: [],
      dialogVisible: false, // 活动主题选择弹窗
      themeText: '', // 选择的主题名称
      purchaseLimitNum1: null,
      purchaseLimitNum2: null,
      /* 最小扣点 */
      minPoint: 2,
      // 时间配置-不准选当日之前的数据
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < (Date.now() - 86400000)
        },
      }
    }
  },
  methods: {
    /**
     * 测试数据
     * */
    pushTestData () {
      if (!1) return this.$message.warning('哈哈哈哈哈~，别点了，没有测试数据，老老实实的自己填 ^_^ ')
      const list = [
        {
          goodsId: '1329309100693065729',
          goodsName: '徐福记奇欧比巧克力散装1000克随机混合装零食糖果',
          goodsCover: 'http://img.lfnc18.com/fzgmall/images/20201119/cc0562fa85618b4cddc85e233958af3f.jpg',
          goodsSkuId: '1329309100898586625',
          goodsSkuName: '2斤 送184酥糖一袋',
          costPercent: 14.99,
          stockNum: 1000,
          selectStatus: 2,
          selectContent: '',
          originalPrice: 59.9,
          originalPurchasePrice: 50.92,
          price: '24',
          purchasePrice: 20.4
        },
        {
          goodsId: '1329324890095947778',
          goodsName: '新疆特产若羌灰枣1-2级混装 3斤装',
          goodsCover: 'http://img.lfnc18.com/fzgmall/images/20201119/2927820e1b764f42e8a22cd6c4884a4e.jpg',
          goodsSkuId: '1329324890267914241',
          goodsSkuName: '3斤散装',
          costPercent: 10,
          stockNum: 1000,
          selectStatus: 2,
          selectContent: '',
          originalPrice: 35,
          originalPurchasePrice: 31.5,
          price: '15',
          purchasePrice: 13.5
        },
        {
          goodsId: '1329324945003581441',
          goodsName: '（年货）徐福记经典包心酥凤梨酥184g*10袋 整箱批发',
          goodsCover: 'http://img.lfnc18.com/fzgmall/images/20201119/f7cf89f7e35d28f6c27c3f32b1a1b28b.jpg',
          goodsSkuId: '1329324945217490946',
          goodsSkuName: '4袋多口味',
          costPercent: 14.99,
          stockNum: 1000,
          selectStatus: 2,
          selectContent: '',
          originalPrice: 39.9,
          originalPurchasePrice: 33.92,
          price: '30',
          purchasePrice: 25.5
        }
      ]
      this.data = {
        endTime: 1614182400000,
        id: '',
        isNotice: 1,
        name: '测试',
        spikeGoodsList: [
          // {
          //   "goodsCover": list[0].goodsCover,// 商品封面图
          //   "goodsId": list[0].goodsId,// 商品ID
          //   "goodsName": list[0].goodsName, // 商品名称
          //   "spikeGoodsBasisList": [ // 秒杀活动sku列表
          //     list[0]
          //   ]
          // }
        ],
        startTime: 1610467200000,
        goodsSkuList: list,
      }
      this.setActivityTimeArray()
    },
    /**
     *
     */
    pageInit () {
      const params = this.$route.query
      this.originPageUrL = params.originPageUrL || ''
      if (params.id) this.getSecondsKillDetails(params.id)
    },
    getSecondsKillDetails (id = '') {
      if (!id) return false
      const data = { id, }
      this.loading = true
      this.axios.get(URL.supplierSpikeGoods.details, { params: data }).then(res => {
        this.loading = false
        res.data.goodsSkuList = this.getOriginGoodsSkuList(res.data)
        this.data = res.data
        if (res.data.isPurchaseLimit == 1) {
          this.purchaseLimitNum1 = res.data.purchaseLimitNum
        } else if (res.data.isPurchaseLimit == 2) {
          this.purchaseLimitNum2 = res.data.purchaseLimitNum
        }
        this.setActivityTimeArray()
        this.getList()
      }).catch(res => {
        this.loading = false
      })
    },
    // 切换限购
    changeType (data) {
      this.purchaseLimitNum2 = null
      this.purchaseLimitNum1 = null
    },
    /**
     * 容器滚动条
     */
    mainScrollEvent (e) {
      const homeMain = document.getElementById('supplierEditSecondsKillEdit')
      const top = homeMain.scrollTop
      this.scrollTop = top
      if (top > 300) {
        this.showBackTop = true
      } else {
        this.showBackTop = false
      }
    },
    goBack,
    goHome,
    goBackTop (e) {
      const homeMain = document.getElementById('supplierEditSecondsKillEdit')
      let top = homeMain.scrollTop
      const spaceNumber = 50
      const topSpace = top / spaceNumber
      const timeOut = setInterval(() => {
        top -= topSpace
        if (top < 0) top = 0
        homeMain.scrollTop = top
        if (top <= 0) clearInterval(timeOut)
      }, parseInt(200 / spaceNumber))
    },
    getList () {
      const self = this
      const data = {
        type: '1',
        name: ''
      }
      return self.axios
        .get(URL.supplierSpikeGoods.listThemeConfigs, { params: data })
        .then(res => {
          res.data.forEach(i => {
            if (i.id == self.data.configId) {
              self.themeText = i.name
            }
          })
          return Promise.resolve(res)
        })
        .catch(res => {
          return Promise.reject(res)
        })
    },
    // 打开选择活动主题弹窗
    openSelectTheme () {
      this.dialogVisible = true
    },
    handleClose (done) {
      done()
    },
    // 确认主题
    confirmTheme () {
      this.dialogVisible = false
      console.log(this.$refs.selectThemeDialog.radio)
      this.data.configId = this.$refs.selectThemeDialog.radio
      this.themeText = this.$refs.selectThemeDialog.themeText
    },
    /** 时间改变 */
    changeActivityTime (arr = []) {
      let startTime = ''
      let endTime = ''
      if (arr.length) {
        startTime = new Date(arr[0]).getTime()
        if (arr.length > 1) endTime = new Date(arr[1]).getTime()
      }
      this.data.startTime = startTime
      this.data.endTime = endTime
    },
    setActivityTimeArray (startTime = this.data.startTime, endTime = this.data.endTime) {
      if (startTime) startTime = parseTime(startTime, 'YYYY-MM-DD HH:mm')
      if (endTime) endTime = parseTime(endTime, 'YYYY-MM-DD HH:mm')
      this.activityTimeArray = [startTime, endTime]
    },
    /**
     * 显示 选择商品弹窗
     */
    showSelectGoods () {
      if (!this.activityTimeArray[1]) return this.$message.error('请选择“开始”及“结束”时间')
      this.dialogVisibleSelectGoods = true
      this.selectGoodsIdArray = deepsClone(this.goodsSkuList)
    },
    hideSelectGoods () {
      this.dialogVisibleSelectGoods = false
      this.selectGoodsIdArray = []
    },
    selectGoodsChange (list) {
      // list = deepsClone(list);
      this.data.goodsSkuList = list.sort((res1, res2) => res1[this.matchKey] - res2[this.matchKey])
    },
    /**
     * 表单合并
     */
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const goodsIds = this.skuListGoodsIds
        if (this.repetitiveGoodsList.includes(row.goodsId)) {
          const index = goodsIds.indexOf(row.goodsId)
          if (index === rowIndex) {
            const num = goodsIds.filter(id => id === row.goodsId).length
            return [num, 1]
          } else {
            return [0, 0]
          }
        }
      }
      return [1, 1]
    },
    /**
     * 表单选中、删除
     */
    selection (selection, row) {
      this.selectThePageSkuArray = selection
    },
    selectionAll (selection) {
      this.selectThePageSkuArray = selection
    },
    clickDeleteSelection (row) {
      this.$confirm(`是否移除当前sku商品【${row.goodsSkuName}】？`, '确认操作').then(() => {
        const index = this.goodsSkuListSkuIds.indexOf(row[this.matchKey])
        if (index >= 0) this.goodsSkuList.splice(index, 1)
      })
    },
    clickDeleteSelectionAll () {
      const len = this.selectThePageSkuArray.length
      if (!len) return this.$message.error('没有选中商品skuList')
      this.$confirm(`是否移除当前选中的${len}个sku商品？`, '确认操作').then(() => {
        const key = this.matchKey
        const ids = this.selectThePageSkuArray.map(res => res[key])
        this.data.goodsSkuList = this.goodsSkuList.filter(res => !ids.includes(res[key]))
        this.selectThePageSkuArray = []
      })
    },
    /** 改变 秒杀售价 计算 供货价
     */
    editPriceInput () { },
    editPrice (row) {
      const price = parseFloat(row.price)
      if (!isNumber(price)) return false
      // let costPercent = parseFloat(row.costPercent);
      // let cost = price * costPercent/100;
      // let purchasePrice = price - cost;
      // purchasePrice = parseFloat(purchasePrice.toFixed(2));
      // this.$set(row,'purchasePrice',purchasePrice);
      if (row.supplierType == 1) {
        if (row.purchasePrice) { // 供货价
          const purchasePrice = parseFloat(row.purchasePrice) || 0
          const cost = price - purchasePrice // 扣点金额
          let costPercent = cost / price * 100
          costPercent = parseFloat(costPercent.toFixed(2))
          this.$set(row, 'costPercent', costPercent)
          this.$refs[this.formNameEdit].validate()
          return false
        }
        if (row.costPercent) { // 扣点%
          const costPercent = parseFloat(row.costPercent) || 0
          const cost = price * costPercent / 100 // 扣点金额
          let purchasePrice = price - cost
          purchasePrice = parseFloat(purchasePrice.toFixed(2))
          this.$set(row, 'purchasePrice', purchasePrice)
          this.$refs[this.formNameEdit].validate()
          return false
        }
      } else {
        if (row.costPercent) { // 扣点%
          const costPercent = parseFloat(row.costPercent) || 0
          const cost = price * costPercent / 100 // 扣点金额
          let purchasePrice = price - cost
          purchasePrice = parseFloat(purchasePrice.toFixed(2))
          this.$set(row, 'purchasePrice', purchasePrice)
          this.$refs[this.formNameEdit].validate()
          return false
        }
      }
    },
    /** 改变 供货价
     */
    editPurchasePrice (row) {
      const purchasePrice = parseFloat(row.purchasePrice)
      if (!isNumber(purchasePrice)) return false
      // if(row.price){ // 售价
      //   let price = parseFloat(row.price) || 0;
      //   let cost = price - purchasePrice; // 扣点金额
      //   let costPercent = cost/price * 100;
      //   costPercent = parseFloat(costPercent.toFixed(2));
      //   this.$set(row,'costPercent',costPercent);
      //   this.$refs[this.formNameEdit].validate();
      //   return false
      // }
      if (row.costPercent) { // 扣点%
        const costPercent = parseFloat(row.costPercent) || 0
        let price = purchasePrice / (1 - costPercent / 100) // 售价
        price = parseFloat(price.toFixed(2))
        this.$set(row, 'price', price)
        this.$refs[this.formNameEdit].validate()
        return false
      }
    },
    /** 改变 扣点
   */
    editCostPercent (row) {
      const costPercent = parseFloat(row.costPercent)
      if (!isNumber(costPercent)) return false
      // if(row.price){ // 售价
      //   let price = parseFloat(row.price) || 0;
      //   let cost = price * (costPercent / 100); // 扣点金额
      //   let purchasePrice = price - cost;
      //   purchasePrice = parseFloat(purchasePrice.toFixed(2));
      //   this.$set(row,'purchasePrice',purchasePrice);
      //   this.$refs[this.formNameEdit].validate();
      //   return false
      // }
      if (row.purchasePrice) { // 供货价
        const purchasePrice = parseFloat(row.purchasePrice) || 0
        let price = purchasePrice / (1 - costPercent / 100) // 售价
        price = parseFloat(price.toFixed(2))
        this.$set(row, 'price', price)
        this.$refs[this.formNameEdit].validate()
        return false
      }
    },
    getGoodsSkuIndex (row) {
      return this.goodsSkuListSkuIds.indexOf(row.goodsSkuId)
    },
    /**
     * 校验秒杀相关价格
     */
    judgeSecondsKillEditPrice (row) {
      const text = '秒杀价'
      let price = row.price
      if ([0, '0'].includes(price)) return ''
      if (!price) return `${text}为空`
      price = parseFloat(price) || 0
      if (price < 0) return `${text} < 0`
      const originalPrice = parseFloat(row.originalPrice)
      if (price > originalPrice) return `${text}<售价`
      return ''
    },
    /**
     * 校验秒杀库存
     */
    judgeSecondsKillEditStockNum (row) {
      const text = '库存'
      let price = row.spikeStockNum
      price = parseInt(price) || 0
      if (price < 1) return `${text}为空`
      return ''
    },
    /**
     * 校验供货价
     */
    judgeSecondsKillEditPurchasePrice (row) {
      const text = '供货价'
      let price = row.purchasePrice
      price = parseFloat(price) || 0
      if (price <= 0) return `${text}为空`
      return ''
    },
    /**
     * 校验秒杀扣点
     */
    judgeSecondsKillEditCostPercent (row) {
      const text = '扣点'
      debugger
      let price = row.costPercent
      if (!price && ![0, '0'].includes(price)) return `${text}为空`
      price = parseFloat(price) || 0
      if (price < this.minPoint) return `${text}<${this.minPoint}%`
      return ''
    },
    /**
     * 校验 整个this.data.goodsSkuList[] 中每条sku的相关数据
     */
    judgeArrayPrice (arr) {
      return arr.every(res => {
        let index = this.getGoodsSkuIndex(res)
        index += 1
        let errorText = ''
        if (!errorText) errorText = this.judgeSecondsKillEditStockNum(res)
        if (!errorText) errorText = this.judgeSecondsKillEditPrice(res)
        if (!errorText) errorText = this.judgeSecondsKillEditPurchasePrice(res)
        if (!errorText) errorText = this.judgeSecondsKillEditCostPercent(res)
        if (errorText) {
          this.$notify.error({
            title: errorText,
            message: `第【${index}】条 商品sku 的 ${errorText}`
          })
        }
        return !errorText
      })
    },
    judgeAllPrice () {
      let judge = true
      if (this.keywords) judge = this.judgeArrayPrice(this.goodsSkuListFilterAfter)
      if (judge) judge = this.judgeArrayPrice(this.goodsSkuList)
      return judge
    },
    judgeFormBaseData () {
      let judge = true
      if (!this.data.name) {
        judge = false
        this.$notify.error({ title: '活动名称为空', message: '活动名称为空' })
      }
      if (judge && !this.goodsSkuList.length) {
        judge = false
        this.$notify.error({ title: '活动商品为空', message: '活动商品列表为空' })
      }
      if (judge && this.goodsSkuList.length > this.maxLength) {
        judge = false
        this.$notify.error({ title: `活动商品条数 > ${this.maxLength}条`, message: `活动商品条数 > ${this.maxLength}条` })
      }
      if (judge) this.judgeAllPrice()
      return judge
    },
    /**
     * 拼接save参数
     */
    getSaveData () {
      const key = this.matchKey
      const data = this.data
      let params = {}
      const addSpikeGoodsList = []
      const deleteSpikeGoodsIdList = []
      const spike = {}
      const updateSpikeGoodsList = []
      if (this.data.isPurchaseLimit == 1 && !this.purchaseLimitNum1) {
        this.$message({
          message: '请输入限购数量',
          type: 'warning'
        })
        return false
      }
      if (this.data.isPurchaseLimit == 2 && !this.purchaseLimitNum2) {
        this.$message({
          message: '请输入享受秒杀价的数量',
          type: 'warning'
        })
        return false
      }
      if (this.data.isPurchaseLimit == 1) {
        this.data.purchaseLimitNum = parseInt(this.purchaseLimitNum1)
      } else if (this.data.isPurchaseLimit == 2) {
        this.data.purchaseLimitNum = parseInt(this.purchaseLimitNum2)
      } else {
        this.data.purchaseLimitNum = 0
      }
      ['id', 'startTime', 'endTime', 'isNotice', 'name', 'configId', 'isPurchaseLimit', 'purchaseLimitNum'].forEach(key => {
        if (data[key] || (key == 'isPurchaseLimit') || (key == 'purchaseLimitNum')) {
          spike[key] = data[key]
        }
      })
      const theList = this.goodsSkuList
      const originList = this.getOriginGoodsSkuList()
      const originListIds = originList.map(res => res[key])

      theList.forEach(res => {
        const item = this.getGoodsSkuListNeedParams(res)
        const index = originListIds.indexOf(item[key])
        if (index < 0) {
          return addSpikeGoodsList.push(item)
        }
        const old = this.getGoodsSkuListNeedParams(originList[index])
        if (JSONStringify(old) !== JSONStringify(item)) {
          updateSpikeGoodsList.push(item)
        }
        originList.splice(index, 1)
        originListIds.splice(index, 1)
      })
      if (originList.length) deleteSpikeGoodsIdList.push(...originList.map(res => res.id))

      params = {
        addSpikeGoodsList, // 新增的秒杀活动商品信息
        deleteSpikeGoodsIdList, // 删除的秒杀活动商品信息Id
        spike, // 限时秒杀信息
        updateSpikeGoodsList, // 修改的秒杀活动商品信息
      }
      return params
    },
    getOriginGoodsSkuList (data = this.data) {
      const list = []
      const one = data.spikeGoodsList
      if (Array.isArray(one) && one.length) {
        one.forEach(res => {
          const two = res.spikeGoodsBasisList
          if (Array.isArray(two) && two.length) {
            two.forEach(item => {
              if (!item.goodsCover) item.goodsCover = res.goodsCover
              if (!item.goodsName) item.goodsName = res.goodsName
            })
            list.push(...deepsClone(two))
          }
        })
      }
      return list
    },
    getGoodsSkuListNeedParams (data = {}) {
      const item = {};
      ['goodsId', 'goodsSkuId', 'id', 'price', 'purchasePrice', 'spikeId', 'supplierId', 'spikeStockNum', 'costPercent'].forEach(key => {
        let value = data[key]
        if (value) {
          if (['price', 'purchasePrice', 'spikeStockNum'].includes(key)) value = parseFloat(value) || 0
          item[key] = value
        }
      })
      return item
    },
    /**
     * save
     */
    save () {
      const data = this.getSaveData()
      if (!data) {
        return
      }
      this.loading = true
      return this.axios.post(URL.supplierSpikeGoods.saveOrUpdate, data).then(res => {
        this.loading = false
        const routeData = this.originPageUrL ? {
          path: this.originPageUrL,
        } : {
          name: 'secondsKill',
        }
        return this.$router.replace(routeData)
        // return Promise.resolve(res);
      }).catch(res => {
        this.loading = false
        // return Promise.reject(res);
      })
    },
    /**
     * 表单提交及重置
     * @param formName
     */
    submitForm (formName = this.formNameEdit) { // 按钮：提交审核
      this.$refs[formName].validate((valid) => {
        if (!this.judgeFormBaseData()) return false
        if (!valid) {
          return false
        }
        this.save()
        // this.$message.success('表单验证通过');
      })
    },
    resetForm (formName = this.formNameEdit) {
      if (typeof formName !== 'string') formName = this.formNameEditStore
      this.$refs[formName].resetFields()
    },
  },
  computed: {
    ...mapState({
      showPoint: state => state.userInfo.showCostAndPurchase,
    }),
    goodsSkuList () {
      return this.data.goodsSkuList || []
    },
    goodsSkuListFilterAfter () {
      const list = this.goodsSkuList
      if (!this.keywords) return list
      const reg = name => {
        return new RegExp(this.keywords, 'ig').test(name)
      }
      return list.filter(res => reg(res.goodsName) || reg(res.goodsSkuName))
    },
    goodsSkuListSkuIds () {
      return this.goodsSkuList.map(res => res[this.matchKey])
    },
    disabledEdit () {
      return [3, 4].includes(this.data.status)
    },
    disabledTime () {
      if (this.disabledEdit) {
        return true
      }
      return ![0, 1].includes(this.data.status)
    },
    // 计算已选择商品sku列表的goodsIds
    skuListGoodsIds () {
      return this.goodsSkuListFilterAfter.map(res => res.goodsId)
    },
    // 计算已选择商品sku列表中存在复数goodsID的列表
    repetitiveGoodsList () {
      const goodsIds = this.skuListGoodsIds
      const ids = Array.from(new Set(goodsIds)).filter((id, index) => {
        return goodsIds.filter(res => res === id).length > 1
      })
      return ids
    },
  },
}
</script>

<style scoped lang="less">
@import "../../../assets/css/data";

.max-width {
  max-width: 600px;
}

.padding-left-label-width {
  padding-left: 160px;
}

.select-goods-list {
  max-width: 1100px;
}
.goods-num {
  margin: 5px 10px;
}
</style>
<style lang="less">
.input-td {
  .cell {
    overflow: visible;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
