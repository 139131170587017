<template>
  <div class="select-theme-dialog-wrap" v-loading="loading">
    <el-input placeholder="请输入活动主题筛选" v-model="screen" class="input-with-select" clearable>
        <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
    </el-input>
    <ul class="infinite-list" style="overflow:auto">
        <el-radio-group v-model="radio" style="width: 100%">
            <li v-for="(i, index) in tableData" class="infinite-list-item" :key="index">
                <el-radio v-model="radio" :label="i.id" @change="change(i.name)">{{i.name}}</el-radio>
            </li>
        </el-radio-group>
    </ul>
  </div>
</template>
<script>
import UploadImagesOne from "../../../components/public/upload/uploadImagesOne";
import * as formRules from "../../../assets/js/formRules";
import { URL } from "../../../config";
export default {
  name: "selectThemeDialog",
  props:['configId'],
  data() {
    return {
      loading: false,
      screen:'',
      themeText:'',
      tableData: [],
      radio: ''
    };
  },
  components: {
    UploadImagesOne
  },
  created() {
    this.getList().then(()=>{
      this.stick()
    });
    this.radio = this.configId;
  },
  watch:{
    'screen': function (newVal, oldVal) {
      if (!newVal) {
        this.getList();
      }
    }
  },
  methods: {
    //初始化
    getList() {
      this.loading = true;
      let data = {
          type:'1',
          name: this.screen
        }
      return this.axios
        .get(URL.supplierSpikeGoods.listThemeConfigs, { params: data })
        .then(res => {
          this.tableData = res.data;
          this.loading = false;
          return Promise.resolve(res);
        })
        .catch(res => {
          this.loading = false;
          this.tableData = [];
          // return Promise.reject(res);
        });
    },
    stick() {
      this.tableData.forEach((item,index) => {
        if (item.id == this.configId) {
          let selectItem = this.tableData.splice(index,1)
          this.tableData.unshift(selectItem[0])
        }
      })
    },
    change(value) {
        this.themeText = value
    },
  }
};
</script>
<style scoped lang="less">
.select-theme-dialog-wrap {
  padding: 0 20px;
}
ul,li{ padding:0;margin:0;list-style:none}
.infinite-list {
    overflow: auto;
    height: 255px;
    margin-top: 10px;
    border: 1px solid#dadada;
    border-radius: 4px;
    padding: 0 10px
}
.infinite-list-item {
    padding: 15px;
    border-bottom: 1px solid #dadada;
}

</style>
